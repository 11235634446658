<template>
  <div>

    <div class="questions" v-if="state=='0'">
      <div class="liidi-question">
      <p>Mistä haluat tarjouksen?</p>
        </div>

      <div class="liidi-valinta">
      <button @click="gotoState('1.0')">SÄRMÄYS</button>
      <button @click="gotoState('2.0')">HITSAUS</button>
      <button @click="gotoState('3.0')">LASERLEIKKAUS</button>
      <button @click="gotoState('4.F')">KOKONAISTOIMITUS</button>
      </div>
    </div>

    <div class="questions" v-if="state=='1.0'">
      <h2>
        SÄRMÄYS - TARJOUSPYYNTÖ
      </h2>
      <div class="liidi-question">
      <p>
        Hei! Vastaa muutamaan kysymykseen, niin saat alustavan tarjouksen särmäyksestä.
      </p>
      </div>
      <div class="liidi-question">
      <p>
        Haluatko särmäyksen lisäksi muita työstöjä/käsittelyitä?
      </p>
      </div>
      <div class="liidi-answer">
      <p>
        <label><input type="checkbox" v-model="answers['muitaPalveluja']" value="Kierteytys">Kierteytys</label>
        <label><input type="checkbox" v-model="answers['muitaPalveluja']" value="Hitsaus">Hitsaus</label>
        <label><input type="checkbox" v-model="answers['muitaPalveluja']" value="Kokoonpano">Kokoonpano</label>
        <label> <input type="checkbox" v-model="answers['muitaPalveluja']" value="Pintakäsittely">Pintakäsittely</label>
        <label><input type="checkbox" v-model="answers['muitaPalveluja']" value="Pelkkä särmäys riittää">Pelkkä särmäys riittää</label>
      </p>
    </div>
      <button @click="gotoState('1.1')">Jatka</button>
    </div>
    <div class="questions" v-if="state=='1.1'">
      <h2>SÄRMÄYS - TARJOUSPYYNTÖ</h2>
      <a href="javascript:void(0)" @click="gotoState('1.0')" class="bk-button">Takaisin edelliseen vaiheeseen</a>
      <div class="liidi-question">
      <p>
        Eräkoko:
      </p>
      </div>
      <div class="liidi-answer">
      <p>
        <label><input type="radio" v-model="answers['erakoko']" value="alle 10">alle 10</label>
        <label><input type="radio" v-model="answers['erakoko']" value="10-50">10-50</label>
        <label><input type="radio" v-model="answers['erakoko']" value="yli 50">yli 50</label>
      </p>
        </div>
      <button @click="gotoState('1.2')">Jatka</button>
    </div>
    <div class="questions" v-if="state=='1.2'">
      <h2>SÄRMÄYS - TARJOUSPYYNTÖ</h2>
      <a href="javascript:void(0)" @click="gotoState('1.1')" class="bk-button">Takaisin edelliseen vaiheeseen</a>

      <div class="liidi-question">
        <p>Toivottu toimitusaika</p>
        </div>
      <p>
        <input type="text" name="toimitusaika" v-model="answers['toimitusaika']">
      </p>
      <button @click="gotoState('1.F')">Jatka</button>
    </div>
    <div class="questions" v-if="state=='1.F'">
      <h2>SÄRMÄYS</h2>
      <a href="javascript:void(0)" @click="gotoState('1.2')" class="bk-button">Takaisin edelliseen vaiheeseen</a>

      <p>
        Hienoa, kiitos! Voit täydentää loput tiedot lomakkeeseen ja lähettää meille tarjouspyynnön.     </p>

    </div>



    <div class="questions" v-if="state=='2.0'">
      <h2>HITSAUS - TARJOUSPYYNTÖ</h2>
      <div class="liidi-question">
      <p>
        Hei! Vastaa muutamaan kysymykseen, niin saat alustavan tarjouksen hitsauksesta.
      </p>
        </div>
      <div class="liidi-question">
      <p>
        Haluatko hitsauksen lisäksi muita työstöjä/käsittelyitä?
      </p>
      </div>
      <div class="liidi-answer">
      <p>
        <label><input type="checkbox" v-model="answers['muitaPalveluja']" value="Särmäys">Särmäys</label>
        <label><input type="checkbox" v-model="answers['muitaPalveluja']" value="Kierteytys">Kierteytys</label>
        <label><input type="checkbox" v-model="answers['muitaPalveluja']" value="Kokoonpano">Kokoonpano</label>
        <label><input type="checkbox" v-model="answers['muitaPalveluja']" value="Pintakäsittely">Pintakäsittely</label>
        <label><input type="checkbox" v-model="answers['muitaPalveluja']" value="Pelkkä hitsaus riittää">Pelkkä hitsaus riittää</label>
      </p>
  </div>
      <button @click="gotoState('2.1')">Jatka</button>
    </div>

    <div class="questions" v-if="state=='2.1'">
      <h2>HITSAUS - TARJOUSPYYNTÖ </h2>
      <a href="javascript:void(0)" @click="gotoState('2.0')" class="bk-button">Takaisin edelliseen vaiheeseen</a>
      <div class="liidi-question">
      <p>Mikä materiaali on kyseessä?</p>
    </div>
      <div class="liidi-answer">
      <label><input type="radio" v-model="answers['materiaali']" value="RST">RST</label>
      <label><input type="radio" v-model="answers['materiaali']" value="RST - hapotettuna">RST - Hapotettuna</label>
      <label><input type="radio" v-model="answers['materiaali']" value="HST">HST</label>
      <label><input type="radio" v-model="answers['materiaali']" value="HST - hapotettuna">HST - Hapotettuna</label>
      <label><input type="radio" v-model="answers['materiaali']" value="Alumiini">Alumiini</label>
      <label><input type="radio" v-model="answers['materiaali']" value="Musta rauta">Musta rauta</label>
        </div>
      <button @click="gotoState('2.2')">Jatka</button>
    </div>
    <div class="questions" v-if="state=='2.2'">
      <h2>HITSAUS - TARJOUSPYYNTÖ</h2>
      <a href="javascript:void(0)" @click="gotoState('2.1')" class="bk-button">Takaisin edelliseen vaiheeseen</a>
      <div class="liidi-question">
      <p>
        Eräkoko:
      </p>
      </div>
      <p>
        <label><input type="radio" v-model="answers['erakoko']" value="alle 10">alle 10</label>
        <label><input type="radio" v-model="answers['erakoko']" value="10-50">10-50</label>
        <label><input type="radio" v-model="answers['erakoko']" value="yli 50">yli 50</label>
      </p>
      <button @click="gotoState('2.3')">Jatka</button>
    </div>
    <div class="questions" v-if="state=='2.3'">
      <h2>HITSAUS - TARJOUSPYYNTÖ</h2>
      <a href="javascript:void(0)" @click="gotoState('2.2')" class="bk-button">Takaisin edelliseen vaiheeseen</a>
      <div class="liidi-question">
        <p>Toivottu toimitusaika</p>
      </div>
        <input type="text" name="toimitusaika" v-model="answers['toimitusaika']">

      <button @click="gotoState('2.F')">Jatka</button>
    </div>
    <div class="questions" v-if="state=='2.F'">
      <h2>HITSAUS - TARJOUSPYYNTÖ</h2>
      <a href="javascript:void(0)" @click="gotoState('2.3')" class="bk-button">Takaisin edelliseen vaiheeseen</a>
      <p>
        Hienoa, kiitos! Voit täydentää loput tiedot lomakkeeseen ja lähettää meille tarjouspyynnön.      </p>
    </div>


    <div class="questions" v-if="state=='3.0'">
      <h2>LASERLEIKKAUS - TARJOUSPYYNTÖ </h2>

      <div class="liidi-question">
        <p>Hei! Vastaa muutamaan kysymykseen, niin saat alustavan tarjouksen laserleikkauksesta.</p>
      </div>
      <div class="liidi-question">
        <p>Haluatko laserleikkauksen lisäksi muita työstöjä/käsittelyitä?</p>
      </div>
      <div class="liidi-answer">
      <label><input type="checkbox" v-model="answers['muitaPalveluja']" value="Särmäys">Särmäys</label>
      <label><input type="checkbox" v-model="answers['muitaPalveluja']" value="Kierteytys">Kierteytys</label>
      <label><input type="checkbox" v-model="answers['muitaPalveluja']" value="Pintakäsittely">Pintakäsittely</label>
      <label><input type="checkbox" v-model="answers['muitaPalveluja']" value="Pelkkä laserleikkaus riittää">Pelkkä laserleikkaus riittää</label>
        </div>
      <button @click="gotoState('3.1')">Jatka</button>
    </div>
    <div class="questions" v-if="state=='3.1'">
      <h2>LASERLEIKKAUS - TARJOUSPYYNTÖ</h2>
      <a href="javascript:void(0)" @click="gotoState('3.0')" class="bk-button">Takaisin edelliseen vaiheeseen</a>
      <div class="liidi-question">
      <p>Mikä materiaali on kyseessä?</p>
      </div>

      <div class="liidi-answer">  <p>
      <label><input type="radio" v-model="answers['materiaali']" value="RST">RST</label>
        <label><input type="radio" v-model="answers['materiaali']" value="RST - hapotettuna">RST - Hapotettuna</label>
        <label><input type="radio" v-model="answers['materiaali']" value="HST">HST</label>
        <label><input type="radio" v-model="answers['materiaali']" value="HST - hapotettuna">HST - Hapotettuna</label>
        <label><input type="radio" v-model="answers['materiaali']" value="Alumiini">Alumiini</label>
        <label> <input type="radio" v-model="answers['materiaali']" value="Musta rauta">Musta rauta</label>
      </p></div>

      <button @click="gotoState('3.2')">Jatka</button>
    </div>

    <div class="questions" v-if="state=='3.2'">
      <h2>LASERLEIKKAUS - TARJOUSPYYNTÖ</h2>
      <a href="javascript:void(0)" @click="gotoState('3.1')" class="bk-button">Takaisin edelliseen vaiheeseen</a>
      <div class="liidi-question">
      <p>
        Eräkoko:
      </p>
      </div>
      <div class="liidi-answer">
      <p>
        <label><input type="radio" v-model="answers['erakoko']" value="alle 10">alle 10</label>
        <label><input type="radio" v-model="answers['erakoko']" value="10-50">10-50</label>
        <label> <input type="radio" v-model="answers['erakoko']" value="yli 50">yli 50</label>
      </p>
        </div>
      <button @click="gotoState('3.3')">Jatka</button>
    </div>
    <div class="questions" v-if="state=='3.3'">
      <h2>LASERLEIKKAUS - TARJOUSPYYNTÖ</h2>
      <a href="javascript:void(0)" @click="gotoState('3.2')" class="bk-button">Takaisin edelliseen vaiheeseen</a>
      <div class="liidi-question">
        <p>Toivottu toimitusaika</p>
      </div>
      <p>
        <input type="text" name="toimitusaika" v-model="answers['toimitusaika']">
      </p>
      <button @click="gotoState('3.F')">Jatka</button>
    </div>
    <div class="questions" v-if="state=='3.F'">
      <h2>LASERLEIKKAUS - TARJOUSPYYNTÖ</h2>
      <a href="javascript:void(0)" @click="gotoState('3.2')" class="bk-button">Takaisin edelliseen vaiheeseen</a>

      <p>
        Hienoa, kiitos! Voit täydentää loput tiedot lomakkeeseen ja lähettää meille tarjouspyynnön.      </p>

    </div>


    <div class="questions" v-if="state=='4.F'">
      <h2>KOKONAISTOIMITUS</h2>

      <p>
        Meiltä saat valmiin tuotteen kokoonpantuna ja pintakäsiteltynä. Täytä lomake ja lähetä meille tarjouspyyntö.
      </p>
    </div>





  </div>


</template>

<script>


export default {
  name: 'App',


  data() {
    return {
      state: '0',
      answers: {
        'muitaPalveluja': [],
        'kohde_sijaitsee': '',
        'toivottu_ajankohta': '',
        'tarjous_tyyppi': '',
      },

    }
  },
  mounted() {
    let firstState=document.getElementById('app').dataset.state;
    if (firstState) {
      this.state =  firstState;
    }
     this.hideForms();
  },
  methods: {
    gotoState(newState) {
      this.updateData();

      this.state = newState;

      //hide old
      this.hideForms();
      if (newState != '0') {
        let element = document.getElementById('liidilomake-' + newState);
        if (element) {
          element.style.display = 'block';
        }

      }
    },
    updateData() {
      this.setFormFields('data-muitaPalveluja', this.answers['muitaPalveluja'].join(', '));
      this.setFormFields('data-erakoko', this.answers['erakoko']);
      this.setFormFields('data-toimitusaika', this.answers['toimitusaika']);
      this.setFormFields('data-materiaali', this.answers['materiaali']);

    },
    setFormFields(className, value) {
      if (typeof value == 'undefined') {
        value = '';
      }
      document.getElementsByClassName(className).forEach(function (item) {
        item.value = value;
      });
    },
    hideForms() {
      document.getElementsByClassName('liidilomake').forEach(function (item) {

        item.style.display = 'none';
      });
    },

  }
}
</script>

<style >

</style>
